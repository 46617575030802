<template>
  <div class="list-details list-con" style="float: none;width: 100%">
    <div
      class="cl"
      style="border-bottom: 1px solid #033476;padding-bottom: 12px;margin-top: 50px"
    >
      <div class="column-title">{{ currentName }}</div>
      <div class="bread">
        <span>首页&nbsp;>&nbsp;</span>
        <span v-show="this.$route.params.name == '新闻与公告'"
          >新闻与公告&nbsp;>&nbsp;</span
        >
        <span>{{ currentName }}</span>
      </div>
    </div>
    <div class="details-titile">{{ details.title }}</div>
    <div style="text-align: center;margin: 20px 0 40px">
      <span style="margin-right: 100px" v-if="details.origin "
        >来源：{{ details.origin }}</span
      >
      <span style="margin-right: 100px">编辑：{{ details.edtor }}</span>
      <span>发布时间：{{ conversionTimestamp(details.riqi) }}</span>
    </div>
    <div class="details-con" v-html="details.content"></div>
  </div>
</template>

<script>
import { detail } from "../api/api";
export default {
  name: "detail",
  data() {
    return {
      list: [],
      currentID: "",
      currentName: "",
      details: ""
    };
  },
  methods: {
    init() {
      let para = `id=${this.$route.params.id}`;
      detail(para).then(res => {
        this.details = res.result.data;
      });
      this.currentName =this.$route.params.type
    },
    conversionTimestamp(timestamp) {
      var date = new Date(parseInt(timestamp));
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return date.getFullYear() + "-" + month + "-" + day;
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/list";
.details-con p img{
  max-width: 100% !important;
}
.details-con{
  overflow: hidden;
}
</style>
